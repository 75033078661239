import { ContentSpacing } from '@rsa-digital/evo-shared-components/components/Form/Field/ErrorContainer/styles';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import { GridItemWrapper } from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import { PanelBodySpacing } from '@rsa-digital/evo-shared-components/components/Panel/LabelledPanel/styles';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import Declaration from 'components/Declaration';
import { QuestionFieldWithMargin } from 'components/Declaration/styles';
import RichTextWithModal from 'components/RichTextWithModal';
import { InfoPanel } from 'components/StatusPanel';

export const InfoPanelWithMargin = styled(InfoPanel)`
  margin: 0 0;
  && {
    padding: ${spacing(2)} ${spacing(2)} ${spacing(3)};

    ${mediaQuery.tabletPortrait`
      padding: ${spacing(2)} ${spacing(3)} ${spacing(3)} ${spacing(2)};
    `}

    ${mediaQuery.tabletLandscape`
      margin-top: ${spacing(2)};
      padding: ${spacing(3)} ${spacing(2)};
    `}
  }

  ${RichText} > :first-child {
    margin-top: 0;
  }
`;

export const RichTextWithFonts = styled(RichTextWithModal)`
  && {
    h3 {
      ${fonts.headingXSmall}
    }

    p {
      ${fonts.paragraph}
      margin-top: ${spacing(0)};

      ${mediaQuery.tabletLandscape`
        ${fonts.paragraphLarge}
      `}
    }
  }
`;

export const GridWithMargin = styled(Grid)`
  && {
    margin-top: ${spacing(0)};
    margin-bottom: ${spacing(0)};
  }
`;

export const DeclarationHeading = styled.h3`
  ${fonts.headingSmall}
  margin: 0;
`;

export const StyledImageDesktop = styled.img`
  display: none;

  ${mediaQuery.tabletPortrait`
    display: inline-block;
    height: ${spacing(4)};
  `}
`;

export const StyledImageMobile = styled.img`
  height: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    display: none;
  `}
`;

export const DeclarationHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LargeRichText = styled(RichTextWithModal)`
  p,
  li {
    ${mediaQuery.tabletLandscape`
      ${fonts.paragraphLarge}
    `}
  }
`;
export const DeclarationWithBottomMargin = styled(Declaration)`
  padding: ${spacing(6)} ${spacing(3)} 0;
  margin-top: 0;
  ${mediaQuery.tabletPortrait`
    padding: ${spacing(4)} ${spacing(3)} 0;
  `};
  > ${Grid} > ${GridItemWrapper} {
    border: none;
    padding: 0;
    margin-bottom: ${spacing(0)};
  }
  && {
    ul {
      padding-top: ${spacing(1)};
      margin-top: ${spacing(0)};
      ${mediaQuery.tabletPortrait`
             padding-top: ${spacing(2)};
    `};
      li {
        ${mediaQuery.tabletPortrait`
               margin: ${spacing(2)} ${spacing(0)} ${spacing(2)} ${spacing(2)};
    `};

        margin: ${spacing(1)} ${spacing(0)} ${spacing(1)} ${spacing(1)};
        padding-left: ${spacing(2)};
        &:before {
          width: ${spacing(1)};
          height: ${spacing(1)};
          left: -${spacing(1)};
          ${mediaQuery.tabletPortrait`
                 left: -${spacing(1.5)};
    `};
        }
      }
    }
  }
  && {
    p + p,
    p + ul,
    ul + p {
      margin-top: ${spacing(3)};
    }
    p + p.small {
      margin-top: ${spacing(2)};
    }
  }
  ${QuestionFieldWithMargin} {
    padding: ${spacing(4)} 0 0;
    margin-bottom: 0;
    margin-top: 0;
    ${mediaQuery.tabletPortrait`
      padding: ${spacing(6)} 0 0;
    `};
    ${({ error, theme }) =>
      error
        ? `${PanelBodySpacing} {
            padding: 0 ${spacing(2)({ theme })};
            
            ${ContentSpacing} {
              width: 100%;
              margin-right: 0;
              margin-left: 0;  
            }   
          }`
        : ''};
  }
`;
