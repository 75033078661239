import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { addYearsToDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import RichTextWithModal from 'components/RichTextWithModal';
import {
  ANNUAL_PAYMENT_PSEUDO_URL,
  MONTHLY_PAYMENT_PSEUDO_URL,
  PageTitle,
  trackSwitchPaymentClick,
} from 'helpers/eventTracking';
import { trackPageView } from 'helpers/pageTracking';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { CurrentQuote, getSelectedProductPrices } from 'helpers/useCurrentQuote';
import useReferenceData from 'state/referenceData/useReferenceData';
import SectionHeadingWithQuoteNumber from './SectionHeadingWithQuoteNumber';
import {
  LabelledDatesWithMargin,
  PricePanelHeading,
  RichTextWithMargin,
  StyledGrid,
  StyledInfoPanel,
  StyledPanelWithActionLink,
} from './styles';

type PaymentSummaryContent = {
  csPetCheckYourDetailsDetailsSectionV2: {
    quote_reference_label: string;
    your_cover_dates: {
      policy_starts: string;
      policy_renewal_date: string;
    };
  };
  csPetSummaryOfCoverInstallmentInfoV2: {
    annual_installment_text: string;
    monthly_installment_text: string;
    switch_to_annual_link_text: string;
    switch_to_monthly_link_text: string;
  };
  csPetMonthlyPayment: {
    payment_summary: {
      heading: string;
      payment_information: string;
    };
  };
  csPetAnnualPayment: {
    payment_summary: {
      heading: string;
      help_box_text: string;
      payment_information: string;
    };
  };
  csPetPaymentV2: {
    cover_heading: string;
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSectionV2 {
      quote_reference_label
      your_cover_dates {
        policy_starts
        policy_renewal_date
      }
    }
    csPetSummaryOfCoverInstallmentInfoV2 {
      annual_installment_text
      monthly_installment_text
      switch_to_annual_link_text
      switch_to_monthly_link_text
    }
    csPetMonthlyPayment {
      payment_summary {
        heading
        payment_information
      }
    }
    csPetAnnualPayment {
      payment_summary {
        heading
        help_box_text
        payment_information
      }
    }
    csPetPaymentV2 {
      cover_heading
    }
  }
`;

type PaymentSummaryProps = {
  isAnnualPayment: boolean;
  switchPaymentType: () => void;
  quote: CurrentQuote;
};

const PaymentSummarySection: React.FC<PaymentSummaryProps> = ({
  isAnnualPayment,
  switchPaymentType,
  quote,
}) => {
  const {
    csPetCheckYourDetailsDetailsSectionV2,
    csPetSummaryOfCoverInstallmentInfoV2,
    csPetAnnualPayment,
    csPetMonthlyPayment,
    csPetPaymentV2,
  } = useStaticQuery<PaymentSummaryContent>(query);

  const plainTextPlaceholderReplacer = (csString: string, isPii: boolean): string =>
    quote
      ? replacePlaceholdersPlainText(quotePlaceholders, quote, isPii)(csString)
      : noOpPlaceholderReplacer(csString);

  const startDate = new Date(quote.policyInfo?.coverStartDate || '');
  const renewalDate = addYearsToDate(startDate, 1);

  const sectionId = 'payment-summary-heading';

  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? [];
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];

  const selectedProductPrices = getSelectedProductPrices(quote);
  return (
    <StyledGrid as="section" alignLeft aria-labelledby={sectionId}>
      <GridItem>
        <SectionHeadingWithQuoteNumber
          heading={plainTextPlaceholderReplacer(csPetPaymentV2.cover_heading, true)}
          quoteNumberLabel={csPetCheckYourDetailsDetailsSectionV2.quote_reference_label}
          quoteNumber={quote.policyInfo?.quoteNumber || ''}
          id={sectionId}
        />
        <PricePanelHeading>
          {isAnnualPayment
            ? csPetAnnualPayment.payment_summary.heading
            : csPetMonthlyPayment.payment_summary.heading}
        </PricePanelHeading>
      </GridItem>
      <GridItem>
        <StyledPanelWithActionLink
          id="price-panel"
          summaryText={
            isAnnualPayment
              ? csPetSummaryOfCoverInstallmentInfoV2.annual_installment_text
              : csPetSummaryOfCoverInstallmentInfoV2.monthly_installment_text
          }
          heading={formatInPoundsWithSign(
            isAnnualPayment
              ? selectedProductPrices?.annualPrice.total
              : selectedProductPrices?.monthlyPrice.total
          )}
          link={{
            onClick: () => {
              trackSwitchPaymentClick(
                PageTitle.Payment,
                isAnnualPayment ? 'monthly' : 'annually'
              );
              trackPageView(
                isAnnualPayment ? MONTHLY_PAYMENT_PSEUDO_URL : ANNUAL_PAYMENT_PSEUDO_URL,
                PageTitle.Payment,
                quote,
                catBreedsRefData,
                dogBreedsRefData,
                undefined,
                isAnnualPayment ? 'Monthly' : 'Annual'
              );
              switchPaymentType();
            },
            text: plainTextPlaceholderReplacer(
              isAnnualPayment
                ? csPetSummaryOfCoverInstallmentInfoV2.switch_to_monthly_link_text
                : csPetSummaryOfCoverInstallmentInfoV2.switch_to_annual_link_text,
              false
            ),
          }}
        />
        {isAnnualPayment && (
          <StyledInfoPanel>
            <RichTextWithModal
              pageTitle={PageTitle.Payment}
              html={csPetAnnualPayment.payment_summary.help_box_text}
            />
          </StyledInfoPanel>
        )}
      </GridItem>
      <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
        <RichTextWithMargin
          pageTitle={PageTitle.Payment}
          html={plainTextPlaceholderReplacer(
            isAnnualPayment
              ? csPetAnnualPayment.payment_summary.payment_information
              : csPetMonthlyPayment.payment_summary.payment_information,
            false
          )}
        />
      </GridItem>
      <GridItem>
        {quote.policyInfo?.coverStartDate && (
          <LabelledDatesWithMargin
            dates={[
              {
                label:
                  csPetCheckYourDetailsDetailsSectionV2.your_cover_dates.policy_starts,
                date: startDate,
              },
              {
                label:
                  csPetCheckYourDetailsDetailsSectionV2.your_cover_dates
                    .policy_renewal_date,
                date: renewalDate,
              },
            ]}
          />
        )}
      </GridItem>
    </StyledGrid>
  );
};

export default PaymentSummarySection;
